(function () {
  let formInputs = document.querySelectorAll('.form input:not([type="submit"]), .form textarea')
  for (let input of formInputs) {
    input.addEventListener('change', function () {
      if (this.value.length > 0) {
        this.classList.add('active')
      } else {
        this.classList.remove('active')
      }
    })
  }

  let contactForm = document.querySelector('.form--contact')
  contactForm.addEventListener('submit', function (e) {
    e.preventDefault();
    let errors = this.querySelectorAll('.error');
    for (let errorEl of errors) {
      errorEl.remove();
    }
    if (validateForm(e)) {
      send(e, '../send.php');
      let inputs = this.querySelectorAll('input, textarea');
      for (let input of inputs) {
        input.value = null;
        input.classList.remove('active')
      }
      this.querySelector('.message').classList.remove('hidden')
    }
  })

  function send(event, php) {
    event.preventDefault ? event.preventDefault() : event.returnValue = false;
    console.log("Sending message");
    let message = event.target.querySelector('.message')
    let req = new XMLHttpRequest();
    req.open('POST', php, true);
    req.onload = function () {
      if (req.status >= 200 && req.status < 400) {
        let json = JSON.parse(this.response);
        if (json.result == "success") {
          console.log("Message has been sent");
          message.textContent = 'Thank you! Message has been sent.'
        } else {
          console.log("Error. Message hasn't been sent");
          message.textContent = 'Something went wrong. Try again'
        }
      } else {
        console.log("Server error. Number: " + req.status);
        message.textContent = 'Something went wrong. Try again'
      }
    };

    req.onerror = function () {
      alert("Request error");
    };
    req.send(new FormData(event.target));
  }


  function validateForm(e) {
    let validate = true;
    validate = validate &&
      isRequiredFieldsFill(e)
    let emailField = e.target.email
    if (emailField) {
      validate = validate && isValidEmail(e)
    }
    return validate;
  }

  function isRequiredFieldsFill(e) {
    let isRequiredFill = true;
    let requiredFields = e.target.querySelectorAll('input.required');
    for (let field of requiredFields) {
      let value = field.value
      if (!value.length) {
        let label = field.nextElementSibling;
        label.insertAdjacentHTML("afterend", '<span class="error">Please, fill require fields</span>')
        isRequiredFill = isRequiredFill && false;
      }
    }
    return isRequiredFill
  }

  function isValidEmail(e) {
    let emailField = e.target.email;
    let email = emailField.value;
    let pattern = new RegExp(/.+@.+\..+/i);
    if (!pattern.test(email)) {
      let label = emailField.nextElementSibling;
      label.insertAdjacentHTML('afterend', '<span class="error">Email is incorrect</span>')
      emailField.focus()
      return false
    } else return true
  }
})()