(function () {
  var mySwiper = new Swiper('.swiper-container', {
    // Optional parameters
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoHeight: true,
    breakpoints: {
      768: {
        autoHeight: false,
      }
    },
    effect: 'fade',
    speed: 1000
  });
})()