import simpleParallax from 'simple-parallax-js';

document.addEventListener("DOMContentLoaded", function() {
  // Don't touch
  objectFitImages();
  svg4everybody();
  // checking webP browser support
  require('./modernizr.js');
  //
  // End don't touch

  require('./target-blank.js');
  require('./menu');
  require('./slider');
  require('./form')
});

// remove preloader
window.addEventListener('load', function () {
  // $('.preloader').fadeOut();
  // $('html').addClass('page-loaded');
  AOS.init({
    once: true,
    useClassNames: true,
    duration: 500,
    delay: 200,
    // easing: 'linear',
    // easing: 'ease',
    // easing: 'ease-in',
    // easing: 'ease-out',
    // easing: 'ease-in-out',
    // easing: 'ease-in-back',
    // easing: 'ease-out-back',
    // easing: 'ease-in-out-back',
    // easing: 'ease-in-sine',
    // easing: 'ease-out-sine',
    // easing: 'ease-in-out-sine',
    // easing: 'ease-in-quad',
    // easing: 'ease-out-quad',
    // easing: 'ease-in-out-quad',
    // easing: 'ease-in-cubic',
    // easing: 'ease-out-cubic',
    // easing: 'ease-in-out-cubic',
    // easing: 'ease-in-quart',
    // easing: 'ease-out-quart',
    easing: 'ease-in-out-quart',

  });
  const image = document.getElementsByClassName('parallax');
  new simpleParallax(image, {
    scale: 1.1
  });
});
