(function() {

  const $header = document.querySelector('.page-header');
  const $menu = document.querySelector('.main-nav');
  const $hamburger = document.querySelector('.burger-menu');
  let isMenuOpened = false;

  $hamburger.addEventListener('click', function () {
    this.classList.toggle('opened')
    $menu.classList.toggle('opened');
    $header.classList.toggle('menu-opened');
    isMenuOpened = !isMenuOpened;
    if (isMenuOpened) {
      bodyScrollLock.disableBodyScroll($menu[0]);
    } else {
      bodyScrollLock.clearAllBodyScrollLocks();
    }
  });

  let navLinks = document.querySelectorAll('.page-header a');
  for (let link of navLinks) {
    link.addEventListener('click', function (e) {
      e.preventDefault();
      let id = this.getAttribute('href');
      let activeSection = document.getElementById(id.slice(1))
      let activeSectionTop = activeSection.getBoundingClientRect().top + window.pageYOffset - activeSection.clientTop - 110;
      window.scroll({
        top: activeSectionTop,
        behavior: 'smooth'
      });
      if (isMenuOpened) {
        let event = new Event("click");
        $hamburger.dispatchEvent(event)
      }
    })
  }

  window.addEventListener('resize', debounce(function () {
      if (isMenuOpened && window.innerWidth >= 768) {
        bodyScrollLock.clearAllBodyScrollLocks();
      }
    })
  );

  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this,
        args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
})();
